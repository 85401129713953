import { MDCSelectEvent } from '@material/select';

export function setupLanguageDropdown() {
    var dropdown = document.getElementById("language-dropdown");

    if (dropdown) {
        dropdown.addEventListener("MDCSelect:change", (event: MDCSelectEvent) => {
            var culture = event.detail.value;
            document.cookie = ".Identity.Nomadis.Culture=c=" + culture + "|uic=" + culture + "; path=/;";
            location.reload();
        });
    }
}
