import { MDCCheckbox } from '@material/checkbox';
import { MDCFormField } from '@material/form-field';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '@material/select';
import { MDCSnackbar } from '@material/snackbar';
import { MDCSwitch } from '@material/switch';
import { MDCTabBar } from '@material/tab-bar';

export function initMaterialSelects() {
    Array.from(document.querySelectorAll('.mdc-select'))
        .forEach(select => {
            select['MDCSelect'] = MDCSelect.attachTo(select);
            const input = select.querySelector('input');
            if (null !== input) {
                select.addEventListener('MDCSelect:change', function (a) {
                    if (select['MDCSelect']) {
                        input.setAttribute('value', select['MDCSelect'].value);
                    }
                });
            }
        });
}

export function initMaterialTextFields() {
    Array.from(document.querySelectorAll('.mdc-text-field'))
        .forEach(textField => {
            textField['MDCRipple'] = MDCRipple.attachTo(textField);
            textField['MDCTextField'] = MDCTextField.attachTo(textField);
        });
}

export function initMaterialButtons() {
    Array.from(document.querySelectorAll('.mdc-button:not(.no-ripple)'))
        .forEach(button => {
            button['MDCRipple'] = MDCRipple.attachTo(button)
        });
}

export function initMaterialCheckboxes() {
    Array.from(document.querySelectorAll('.mdc-checkbox'))
        .forEach(checkboxElement => {
            const checkbox = MDCCheckbox.attachTo(checkboxElement);
            checkbox['MDCCheckbox'] = checkbox;
            const formFieldElement = checkboxElement.closest('.mdc-form-field');
            if (formFieldElement) {
                const formField = MDCFormField.attachTo(formFieldElement as HTMLElement);
                formField.input = checkbox;
            }
        });
}

export function initMaterialSnackbars() {
    Array.from(document.querySelectorAll('.mdc-snackbar'))
        .forEach(snackbarElement => {
            const snackbar = new MDCSnackbar(snackbarElement);
            snackbar['MDCSnackbar'] = snackbar;

            if (snackbarElement.className.indexOf('open') >= 0) {
                snackbar.open();
            }
        });
}

export function initMaterialSwitchs() {
    Array.from(document.querySelectorAll('.mdc-switch'))
        .forEach(ele => {
            ele['MDCSwitch'] = MDCSwitch.attachTo(ele as HTMLElement);
        });
}

export function initMaterialTabs() {
    Array.from(document.querySelectorAll('.mdc-tab-bar'))
        .forEach(ele => {
            ele['MDCTabBar'] = MDCTabBar.attachTo(ele);
        });
}

export function initMaterialComponents() {
    initMaterialSelects();
    initMaterialTextFields();
    initMaterialButtons();
    initMaterialCheckboxes();
    initMaterialSnackbars();
    initMaterialSwitchs();
    initMaterialTabs();
}